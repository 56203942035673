<template>
    <default-template>
        <v-layout justify-center align-start>
            <v-responsive width="100wv" class="sub-contents">
                <div class="container">
                    <div class="sub_head_title clearfix">
                        <div class="fLeft">
                            <h1>회사소개</h1>
                        </div>
                    </div>
                    <div class="about-section">
                        <div class="img-wrap"><img src="/images/pinbox/about-img.png" alt="건물이미지"></div>
                        <p class="page-text"><strong class="color-orange">콘앤북</strong>은 <strong class="color-orange">상품권
                                대량 매입의 표준</strong>을 제공하며 <br class="pc"><strong>24시간 편리하고 안전하게 구매 가능합니다</strong></p>
                    </div>
                    <div class="about-section2">
                        <div class="location">
                            <div class="location-left">
                                <ul class="list-style">
                                    <li><em>업종</em>상품권유통 / 서비스</li>
                                    <li><em>주소</em>경기도 광명시 일직로12번길 19, 1동 314호(일직동, 동일센타시아)</li>
                                    <li><em>전화번호</em>010-8020-5075</li>
                                    <li><em>휴일</em>연중 무휴(24시간 영업)</li>
                                </ul>
                            </div>
                            <div class="location-right">
                                <div class="map">
                                    <iframe
                                        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3168.6768178691705!2d126.88044301558558!3d37.42111304020011!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x357b61ee4e06179b%3A0xa2a25b21b2f4b266!2z64-Z7J287IS87YOA7Iuc7JWE!5e0!3m2!1sko!2skr!4v1671699495298!5m2!1sko!2skr"
                                        width="100%" height="100%" frameborder="0" allowfullscreen="allowfullscreen"
                                        aria-hidden="false" tabindex="0" style="border: 0px;"></iframe></div>
                            </div>
                        </div>
                    </div>
                </div>
            </v-responsive>
        </v-layout>
    </default-template>
</template>
<script>
import { mapState } from 'vuex';
import DefaultTemplate from "../DefaultTemplate.vue";
export default {
    components: {
        DefaultTemplate
    },
    computed: {
        ...mapState(["siteInfo"]),
    }
}
</script>

<style scoped>

ul {
    list-style: none;
}

img { display: block; margin: 0; border: none; max-width: 100%; }

.bg-light { background-color: #f3f6f8; }
.bg-light2 { background-color: #fafafa; }
.bg-blue { background-color: #33abe6; }
.color-orange{color:#fca932;}



.sub_head_title {
    position: relative;
    line-height: 28px;
    padding-bottom: 20px;
    margin-bottom: 40px;
}
.sub_head_title .fRight { display: none; }
.sub_head_title .fLeft{
    float: none;
    display: block;
    text-align: center;
}
.sub_head_title h1 {
    font-size: 26px;
    font-weight: 600;
    color: #db1d27;
}
.sub_head_title h1:before{
    content:'';
    position: absolute;
    bottom:0;
    left:50%;
    transform: translate(-50%,0);
    display:block;
    width:8px;
    height:1px;
    background-color: #db1d27;
}
.sub_head_title .list { margin-top: 8px; }
.sub_head_title .list a { padding-right: 20px; background: url('/child/img/icon/icon_sub_arrow.png') no-repeat center right 5px; cursor: default; }
.sub_head_title .list a span { font-size: 14px; color: #666666; }
.sub_head_title .list a:first-child span { margin-top: 3.5px; display: inline-block; vertical-align: ã…¡ã…‘ã…‡ì»; width: 16px; height: 13px; font-weight: 400; /*background: url('/child/img/icon/icon_sub_nav.png') no-repeat center;*/ }
.sub_head_title .list a:last-child { padding-right: 0; background: none; }

.sub-contents { padding: 90px 0 150px; }

.page-text{
    font-size: 32px;
    text-align: center;
    margin-bottom: 20px;
}
.page-text strong{
    font-weight: 600;
}
@media(max-width:992px){
    .page-text{
        font-size: 28px;
        margin-bottom: 10px;
    }
}
@media(max-width:768px){
    .page-text{
        font-size: 24px;
    }
}
@media(max-width:576px){
    .page-text{
        font-size: 20px;
    }
}

/* 회사소개 */
.about-section{
    padding-bottom: 70px;
    margin-bottom: 70px;
    border-bottom:1px solid #ddd;
}
.about-section .img-wrap{
    margin-bottom: 80px;
}
.about-section2{
    padding: 0 45px;
}
.location{
    display: flex;
    flex-wrap: wrap;
}
.location-left{
    width: 50%;
}
.location-right{
    width:50%;
}
.list-style {
    padding-left: 0px;
}
.list-style li{
    position: relative;
    min-height: 27px;
    font-size: 16px;
    color: #707070;
    padding-left: 125px;
    margin-bottom: 25px;
}
.list-style li:last-child{
    margin-bottom: 0;
}
.list-style li em{
    position: absolute;
    top: 0;
    left: 0;
    width: 100px;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    text-align: center;
    color: #fff;
    background-color: #db1d27;
    border-radius: 100px;
    padding: 2px 10px;
}
.list-style li:nth-child(even) em{
    background-color: #fca932;
}
.map{
    height: 100%;
    border-radius: 20px;
    overflow: hidden;
}
@media(min-width:1025px){
}
@media(max-width:1024px){
}
@media(max-width:992px){
    .about-section{
        padding-bottom:30px;
        margin-bottom: 30px;
    }
    .about-section .img-wrap{
        margin-bottom: 50px;
    }
    .about-section2{
        padding:0;
    }
    .location-left, .location-right{
        width:100%;
    }
    .location-left{
        order: 2;
    }
    .location-right{
        order:1;
        margin-bottom: 20px;
    }
}
@media(max-width:768px){
    .about-section .img-wrap{
        margin-bottom: 30px;
    }
    .list-style li, .list-style li em{
        font-size: 14px;
    }
    .list-style li{
        padding-left: 100px;
        margin-bottom: 10px;
    }
    .list-style li em{
        width: 90px;
    }
}
@media(max-width:576px){
}


</style>